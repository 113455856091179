/*
 * @Author: your name
 * @Date: 2022-01-08 16:00:16
 * @LastEditTime: 2022-01-26 21:01:48
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\utils\index.js
 */
// 在限定范围产生随机数
export function getRandomNum(min, max) {
    return (Math.random() * (max - min) + min).toFixed(1);
}

// 生成[min, max) 的随机整数
export function getIntegerRandomNum(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

class NewTimer {
    constructor(duration, timeFn, downFn) {
        this.duration = duration;
        this.timeFn = timeFn;
        this.downFn = downFn;
    }
    startCountDown() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve();
            }, duration);
        });
    }
};

/**
 * 函数防抖
 */
 export function debounce(func, wait = 500, immediate = true) {
    let timer;
  
    return function(...args) {
      //this指向debounce
      let context = this;
  
      //如果timer不为null, 清除定时器
      if (timer) clearTimeout(timer);
  
      //如果是立即执行
      if (immediate) {
        //定义callNow = !timer
        var callNow = !timer;
        //定义wait时间后把timer变为null
        //即在wait时间之后事件才会有效
        timer = setTimeout(() => {
          timer = null;
        }, wait);
        //如果callNow为true,即原本timer为null
        //那么执行func函数
        if (callNow) func.apply(context, args);
      } else {
        //如果是不立即执行
        //那就是每次重新定时
        timer = setTimeout(function() {
          func.apply(context, args);
        }, wait);
      }
    };
  }
  
  // 获取url中指定参数的值 variable：想要获取的参数名
  export function getQueryValue(variable) {
    if (window.location.href.indexOf(variable) == -1) {
      return null;
    }
    var arr = window.location.href.split("?");
    var query = arr[arr.length - 1];
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return null;
  }
  // 是否为微信
  export function isWeChat() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase(); //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    return ua.match(/MicroMessenger/i) == "micromessenger";
  }
  // 判断设备
  export function getDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIos =
      userAgent.match(/iphone os/i) == "iphone os" ||
      userAgent.match(/ipad/i) == "ipad";
    const isAndroid = userAgent.match(/android/i) == "android";
    if (isIos) {
      return 1;
    }
    if (isAndroid) {
      return 2;
    }
    return 3;
  }