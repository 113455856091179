<!--
 * @Author: your name
 * @Date: 2022-01-07 14:11:52
 * @LastEditTime: 2022-01-29 11:00:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\layout\index.vue
-->
<template>
  <div class="app-wrapper" >
    <!-- <div style="position:absolute; top:10px;right:40px;color:#fff;font-size:12px;">
      <div class="status_login" v-if="!userInfo" key="login">
        <span v-debounce="login">请登录</span>
      </div>
      <div class="status_login" v-else>
        <span>欢迎您！</span>
        <span class="serve">{{ userInfo.role_name || "" }}</span>
        <span class="login" v-debounce="()=>{islogout = true}" key="logout">【退出】 </span>
      </div>
    </div> -->
    <monopoly v-show=" activeIndex == 1" />
    <shop v-show=" activeIndex == 2" />
    <!-- 登录 -->
    <Login :show="false" />
    <!-- 绑定 -->
    <Bind :show="bindrole" />
    <!-- 规则弹框 -->
    <Rule />
    <success />
    <Address />
    <!-- 导航 -->
    <div class="tab">
      <div :class="{'btn-m':true, 'active': activeIndex == 1}" @click="toggle(1)"></div>
      <div :class="{'btn-s':true, 'active': activeIndex == 2}" @click="toggle(2)"></div>
    </div>
    <!-- 全局loading -->
    <van-loading
      v-if="$store.state.loading"
      class="global-loading"
      type="spinner"
    />
    <!-- <Mymodal :show="islogout" :title="'退出登录'" :urlTitle="false" @sure="logout" :suretext="suretext" :canceltext="canceltext" surebtn cancelbtn @close="islogout = false" >
      <div class="tit">确认退出此账号？</div>
    </Mymodal> -->
  </div>
</template>

<script>
// import { clicklog } from "@/utils/api.js";
import  {mapState} from 'vuex'
import monopoly from '@/components/content/monopoly'
import shop from '@/components/content/shop'
// import { isWeChat,getQueryValue} from '@/utils/index'
export default {
  name: "home",
  components: {
    monopoly,
    shop,
    Login: () => import("@/components/aboutLogin/login"),
    Bind: () => import("@/components/aboutLogin/bind"),
    Rule: () => import("@/components/Modal/rule"),
    short: () => import("@/components/Modal/short"),
    Address: () => import("@/components/Modal/address"),
    success: () => import("@/components/Modal/success")
  },
  computed: {
    ...mapState({
      loginStep: (state) => state.loginStep,
      bindrole: (state) => state.bindrole,
      userInfo: (state) => state.userInfo?.info,
    }),
  },
  data() {
    return {
      ruleshow: false,
      activeIndex: 1,
      endIndex: false,
      // 确认对出登录
      islogout:false,
      suretext:' ',
      canceltext:' ',
    };
  },
  methods: {
    tokefu() {
      window.open(
        "https://customer.zanbugames.com/website/games/youxi/html/index.html?v=1.0"
      );
    },
    toggle(index){
      this.activeIndex = index
    },
    toggleRule() {
      this.ruleshow = !this.ruleshow;
    },
    login() {
      this.$store.commit("SETVAL", { loginStep: "logintype" });
    },
    change(data) {
      // this.activeIndex = data;
      this.swiper.slideTo(data);
    },

    logout() {
      this.islogout = false
      this.$store.commit("SETVAL", { userInfo: null });
      localStorage.removeItem("uid");
      localStorage.removeItem("channel");
      localStorage.removeItem("server");
      localStorage.removeItem("code");
      localStorage.removeItem("token");
    },
  },
  mounted() {
    // let token = localStorage.getItem("token");
    // if (token) {
    //   this.$store.dispatch("getUserInfo", token);
    // }

    //  // 有orderid 去获取订单状态  
    // if(localStorage.getItem('orderid')){
    //   console.log('开始轮询',localStorage.getItem('orderid'))
    //   this.$store.dispatch("repeatsearch", token);
    // }

  },
};
</script>

<style lang="less" scoped>
.app-wrapper {
  padding-bottom:100px;
}
  .tab {
    position: fixed;
    bottom: 0;
    width: 750px;
    // background: #000;
    display: flex;
    z-index: 3;
    .btn-m {
      .set-bg(375px,100px,'nl.jpg');
      &.active {
        .set-bg(375px,100px,'nla.jpg');
      }
    }
    .btn-s {
      .set-bg(375px,100px,'nr.jpg');
      &.active {
        .set-bg(375px,100px,'nra.jpg');
      }
    }
  }
  .tit {
    width: 100%;
    text-align: center;
    font-size: 42px;
    line-height: 40px;
    color: #C04D34;
    margin-bottom: 60px;
  }
</style>

