// 闯关奖励
export const mapgames = {
  1: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  2: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  3: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  4: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  5: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],
};

export const shoplist = [
  {
    name: "惊喜礼包",
    limit: 0,
    price: 6,
    list: [
      { name: "盒子", num: 10, img: "bicon" },
    ],
  },
  {
    name: "精怪礼包",
    limit: 0,
    price: 30,
    list: [
      { name: "盒子", num: 50, img: "bicon" },
    ],
  },
  {
    name: "鬼马礼包",
    limit: 0,
    price: 98,
    list: [
      { name: "盒子", num: 180, img: "bicon" },
    ],
  },
  {
    name: "至上礼包",
    limit: 0,
    price: 198,
    list: [
      { name: "盒子", num: 400, img: "bicon" },
    ],
  },
  {
    name: "无限礼包",
    limit: 0,
    price: 328,
    list: [
      { name: "盒子", num: 1000, img: "bicon" },
    ],
  },
];

export const convertShop = [
  { name: "千金抱枕", num: 1, img: "item300000000000", spend: 100, limit: 50, changeNum: 0, price: 28 },
  { name: "千金购物袋", num: 1, img: "item300000000001", spend: 200, limit: 2, changeNum: 0, price: 69 },
  {
    name: "千金礼盒",
    num: 1,
    img: "item300000000002",
    spend: 1000,
    limit: 50,
    changeNum: 0, 
    price: 588
  },
  {
    name: "毯子（男）",
    num: 2,
    img: "item300000000003",
    spend: 300,
    limit: 50,
    changeNum: 0,
    price: 98
  },
  {
    name: "毯子（女）",
    num: 2,
    img: "item300000000004",
    spend: 300,
    limit: 50,
    changeNum: 0,
    price: 98
  },
  {
    name: "千金玩偶",
    num: 1,
    img: "item300000000005",
    spend: 500,
    limit: 50,
    changeNum: 0,
    price: 198
  },
];
