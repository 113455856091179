/*
 * @Author: your name
 * @Date: 2022-01-08 16:00:16
 * @LastEditTime: 2022-01-28 10:28:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Layout from "@/layout";
import Index from "@/views/index";

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH, 
  // base: '/guonei/sezg/springfestival',
  // /spring_festival
//   base:process.env.NODE_ENV === "production" ?'/spring_festival':'./',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
