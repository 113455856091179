/*
 * @Author: your name
 * @Date: 2022-01-07 14:11:52
 * @LastEditTime: 2022-01-20 15:55:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Toast,Stepper  } from "vant";
import "@/assets/style/reset.less";
import "@/assets/style/common.less"
import "@/utils/flexible";
import { Loading, Lazyload ,Slider ,Picker, Popup, Field  } from "vant";
import Modal from "@/components/Modal"; //引入组件
import vhCheck from "vh-check";

vhCheck({
    cssVarName: 'browser-address-bar',
});
Toast.setDefaultOptions({ duration: 1500 });
Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;
Vue.prototype.$bus = new Vue();
Vue.use(Loading);
Vue.use(Slider);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Field );
Vue.use(Stepper );
Vue.component("Mymodal", Modal);
Vue.use(Lazyload, {
    lazyComponent: true,
});

Vue.directive("debounce", {
  inserted: function(el, binding) {
    let timer;
    el.addEventListener("click", () => {
      if (timer) {
        clearTimeout(timer);
      }
      //定义callNow = !timer
      var callNow = !timer;
      //定义wait时间后把timer变为null
      //即在wait时间之后事件才会有效
      timer = setTimeout(() => {
        timer = null;
      }, 500);
      //如果callNow为true,即原本timer为null
      //那么执行func函数
      if (callNow) binding.value();
      // timer = setTimeout(() => {
      //   binding.value();
      // }, 500);
    });
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
