/*
 * @Author: your name
 * @Date: 2022-01-08 19:38:47
 * @LastEditTime: 2022-01-28 14:46:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\utils\api.js
 */
import { get, post } from "./http.js";
import store from '../store/index.js'
import { getDeviceType } from "@/utils/index"
// export const serverlist = p => get('serverlist', p)
// export const price = p => get('getprice', p)
// export const checkuser = p => get('userinfo', p)
// export const pay = p => post('pay', p)

const salt = "0391591aafc5db68b08787645b837b4f";

/*
 * 登录
 */
export const login = (p) => {
  return post("activity/sr_fool/login", p);
};

/*
 * 游戏内快速登录
 */
export const quicklogin = (p) => {
  return post("activity/sr_fool/xy_login", p);
};
/*
 * 获取角色列表
 */
export const getRoleInfo = (p) => {
  return post("activity/sr_fool/getRoleInfo", p);
};

/*
 * 绑定角色
 */
export const bindRole = (p) => {
  return post("activity/sr_fool/bindRole", p);
};
/*
 * 获取用户信息
 */
export const getUserInfo = (p) => {
  return post("activity/sr_fool/getUserInfo", p);
};


/*
 * 获取订单状态
 */
export const getOrderStatus = (p) => {
  return post("activity/sr_fool/get_order_status", p);
};

/*
 * 抽奖
 */
export const lottery = (p) => {
  return post("activity/sr_fool/lottery_draw", p);
};


/*
 * 领奖/兑换
 */
export const subPrize = (p) => {
  let time = parseInt(new Date().getTime());
  let token = localStorage.getItem('token')
  let uid = localStorage.getItem('uid')
  let params = {
    time:time,
    token:token,
    num:1,
    type:1,
    uid:uid,
    flag:0,
    rid: 1,
    
  }
  params = Object.assign(params,p)

  params.access=[time,token,params.num]
  return post("activity/sr_fool/subPrize", params);
};



/*
 * pay 支付
 */
export const pay = (p) => {
    return post("activity/sr_fool/pay", p);
};



/*
 * 发送验证码
 */
export const sendCode = (p) => {
  return post("common/sendcode/sendCode", p);
};
  
/*
 * 埋点
 */
export const clicklog = (p) => {
  let uid = localStorage.getItem('uid')|| ''
  let time = parseInt(new Date().getTime());
  p = {
    time:time,
    project_id: 40,
    project: "塞尔之光",
    type: 1,
    from: 1,
    dev: getDeviceType(),
    page_index: 1,
    state: "",
    tag: "",
    uid: uid,
    access:[time],
    ...p,
  };
  return post("common/clicklog/click", p);
};