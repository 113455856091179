import axios from "axios";
import QS from "qs";
import store from "../store/index";
import md5 from 'js-md5';
// 大陆： https://api.xianyuyouxi.com/service/activity/zjfh_third_ani/

// 海外： https://hw.xianyuyouxi.com/service/activity/zjfh_third_ani/
https://api.xianyuyouxi.com/service/activity/sr_queen/
// 环境的切换
if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL = 'https://api.xianyuyouxi.com/service/';
} else if (process.env.NODE_ENV == 'debug') {    
    axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {    
    axios.defaults.baseURL = 'https://api.xianyuyouxi.com/service/';
}

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
// axios.interceptors.request.use(
//     config => {
//         // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
//         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
//         if(config.url == 'getGoods') {
//             axios.defaults.baseURL ="https://api.xianyuyouxi.com/service/sezg/Hanghai/"
//         }
//         return config;
//     },
//     error => {
//         return Promise.error(error);
//     })
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function encrypte(params){
  let str ;
	const key = '0391591aafc5db68b08787645b837b4f';
  if(Array.isArray(params) && params.length >0) {
    str = params.reduce(((x,y)=>x+''+y))
		str = key+str
		// console.log('str',str,params)
    return md5(str)
  } else {
    return str
  }
  
}

export function get(url, params){    
	let data = JSON.parse(JSON.stringify(params))
	let access = encrypte(data.access)
	delete data.access
	return new Promise((resolve, reject) =>{    
			axios.get(url,{params:data,headers:{"Access-s":access}})        
			.then(res => {            
				if(res.data.status === 1) {
					resolve(res.data.data);       
				}  else {
					reject(res.data)    
				}  
			})        
			.catch(err => {            
					reject(err.data)        
			})    
	});
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {  
	let data = JSON.parse(JSON.stringify(params))
	let access = encrypte(data.access)
	delete data.access
	let config = {
		headers:{"Access-s":access,}
	}
	return new Promise((resolve, reject) => {  
			let p ;
			if(config && config.headers['Content-Type'] === 'multipart/form-data') {
					p = data
			}else {
					p = QS.stringify(data)
			}
			axios.post(url, p ,config)        
			.then(res => {
				// if(typeof res.data === 'string') {
				// 	resolve(res.data);        
				// }
				if(res.data.status === 1 ) {
					resolve(res?.data?.data);        
				} else if (res?.data?.status == 4040) {
					localStorage.clear();
					sessionStorage.clear();
                    store.commit('SETVAL', {  userInfo: null})
				}else if(res?.data == 'success') { // 打点成功
          resolve(res?.data)
        }else {
            reject(res?.data)  
				}
			})        
			.catch(err => { 
					reject(err?.data)        
			})    
	});
}
