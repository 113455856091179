<template>
    <Mymodal
      :show="show"
      @close="close"
      @sure="close"
    >
      <div class="title ">商品介绍</div>
      <div class="content">
        <img :src="`https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${store.img}.png`" alt="">
        <div class="detail">
          <div class="detail_item store_name">{{ store.name }}</div>
          <div class="detail_item store_price">￥{{ store.price }}.00</div>
          <div class="detail_item store_time">本商品预计发货时间为<span class="time">2023年12月30日</span></div>
        </div>
        <div>
          <span class="tip">注意：</span> <br/>
          <span class="tip">1. 以上图片仅供参考，一切请以实物为准。</span> <br/>
          <span class="tip">2. 由于产品的性质、工艺和成品效果等因素的影响，产品的实物成品图案可能与上方效果图存在部分差异，请知悉。</span> <br/>
          <span class="tip">3. 官方承诺包邮。</span>
        </div>
      </div>
  
    </Mymodal>
  </template>
  <script>
    import { mapState } from "vuex"
    export default {
      name: '',
      props: {
        store:{
            type:Object,
            require:true
        },
      },
      computed:{
        ...mapState({
          show:(state)=>state.dialog === 'storeDetail'
        })
      },
      data(){
        return {
        }
      },
      methods:{
        close(){
          this.$store.commit('SETVAL',{dialog:''})
        }
      },
      mounted(){
      },
    }
  </script>
  <style scoped lang='less'>
  .content {
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 180px;
      height: 180px;
    }
    .detail {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .detail_item {
        margin-bottom: 20px;
        .time {
          color: #ff3658;
        }
      }
      .store_price {
        color: #ff3658;
      }
      .store_time {
        font-size: 30px;
      }
    }
  }
  </style>