<template>
  <Mymodal
    :show="show"
    @close="close"
    @sure="close"
  >
    <div class="title1"  v-if="cgift && cgift.name">确认购买，{{cgift.name}}？</div>
    <div class="price">￥{{cgift.price}}</div>
    <div class="list" v-if="cgift && cgift.list">
      <div class="single" v-for="item in cgift.list">
        <div class="img-info">
          <img  :src="`https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${item.img}.png`"  class="g-img">
          <div class="num" ref="num">X{{item.num}}</div>
        </div>
        <div class="expr">{{item.name}}</div>
      </div>
    </div>

    <div class="box">
      <div class="cancle" @click="close"></div>
      <div class="sure" v-debounce="tobuy"></div>
    </div>
  </Mymodal>
</template>
<script>
  import { mapState } from "vuex"
  import { isWeChat} from '@/utils/index'
  import { pay } from '@/utils/api.js'
  export default {
    name: '',
    computed:{
      ...mapState({
        show:(state)=>state.dialog === 'buy',
        cgift:(state)=> {
          return state.cgift ? state.cgift : {
          name:'',
          price:0,
          list:[]
        }
        },
      })
    },
    data(){
      return {
        value:'',
      }
    },
    methods:{
      close(){
        this.$store.commit('SETVAL',{dialog:''})
      },
      tobuy(){
        if(!this.$store.state.userInfo?.bnum) {
          this.$store.state.userInfo = {
            ...this.$store.state.userInfo,
            bnum: 0
          }
        }
        if(!this.$store.state.userInfo?.used_bnum) {
          this.$store.state.userInfo = {
            ...this.$store.state.userInfo,
            used_bnum: 0
          }
        }
        this.$toast('购买成功！')
        let num = Number(this.$refs.num[0].innerHTML.slice(1))
        this.$store.state.userInfo.bnum += num
        this.$store.state.userInfo.used_bnum += this.cgift.price
        this.$store.commit('SETVAL',{dialog:'buysuccess'})
        // if( this.cgift.limit > 0 && this?.order?.[this.cgift.index] >= this.cgift.limit  ){
        //   this.$toast('已达购买上限！')
        //   return
        // }
        // let time = parseInt(new Date().getTime());
        // let token = localStorage.getItem('token')
        // let uid = localStorage.getItem('uid')

        // // this.$store.dispatch('getOrderstatu')
        // let params = {
        //   time,
        //   token,
        //   uid,
        //   good_id:this.cgift.index,
        //   access:[time,token]
        // }
        // pay(params).then(data=>{
        //   localStorage.setItem('orderid',data?.orderid)
        //   location.href = data.url;
        //   this.$store.dispatch('getOrderstatu')
        // }).catch(err=>{
        //   this.$toast.fail(err.msg || '购买失败')
        // })
      }
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
  .list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width:650px;
  margin: 0 auto;
  padding-top:50px;
  .single {
    flex : 0 0 33%;
    margin-bottom: 20px;
    .img-info{
      width: 157px;
      height: 157px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom,#ffe1b7 40%,#fff);
      border-radius: 50%;
      position: relative;
      margin: 20px auto;
      .g-img{
        width: 125px;
      }
      .num {
        position: absolute;
        line-height: 37px;
        .set-bg(93px,37px,'ricon.png');
        color:#fff;
        top: 0px;
        right: -30px;
        font-size: 24px;
        box-sizing: border-box;
        padding-right: 20px;
        text-align: center;
      }
    }
    .expr {
      font-size: 24px;
      color: #999999;
      text-align: center;
    }
  }
}
.price {
  text-align: center;
  color:#D1401E;
  font-size: 40px;
}
  .cancle {
    .set-bg(272px,105px,'cancle.png');
  }
  .sure{
    .set-bg(272px,105px,'sure.png');
  }
  .box {
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }
</style>