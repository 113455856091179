<template>
  <Mymodal
    :show="show"
    @close="close"
    @sure="close"
  >
    <div class="title ">盒子获取</div>
    <img  src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/bicon.png" alt="" class="box-img">
    <div class="txt">您本次掷骰所需盒子数量不足，
      请到下面的途径获取~</div>
    <div class="to-item">
      <span>每日签到</span>
      <div class="btn" @click="$emit('linkTo','singin')">前往</div>
    </div>
    <div class="to-item">
      <span>购买礼包</span>
      <div class="btn"  @click="$emit('linkTo','listbuy')">前往</div>
    </div>

  </Mymodal>
</template>
<script>
  import { mapState } from "vuex"
  export default {
    name: '',
    computed:{
      ...mapState({
        show:(state)=>state.dialog === 'short'
      })
    },
    data(){
      return {
      }
    },
    methods:{
      close(){
        this.$store.commit('SETVAL',{dialog:''})
      }
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
.box-img {
  display: block;
  margin: 0 auto;
  width: 93px;
}
.txt {
  width: 370px;
  height: 61px;
  font-size: 26px;
  color: #333333;
  line-height: 36px;
  text-align: center;
  margin: 0 auto 30px auto;
}
.to-item {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;
  line-height: 120px;
  border-bottom: 1px solid rgba(212, 212, 212, 6);
align-items: center;
  span{
    font-size: 42px;
    color: #D1401E;
  }
  .btn{
    width: 180px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    color: #fff;
    background: linear-gradient(to bottom , #fec05e , #FAA51D);
    border-radius: 10px;
  }

}
</style>