/*
 * @Author: your name
 * @Date: 2022-01-08 16:00:16
 * @LastEditTime: 2022-01-27 20:03:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import { Toast } from "vant";
import { getUserInfo,subPrize,getOrderStatus} from "@/utils/api.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
		// 登录角色弹框
		loginStep:'',
		// 绑定角色弹框
		bindrole:false,
    userInfo:null,
    // 全局loading
    loading: false,
    
    // 控制所有弹框
    dialog:'',

    // 领取成功弹框奖励内容
    prizecon:[],

    // 获取的金币数
    coinNum: 0,

    // 购买的商品
    cgift:null,

    // 轮训订单状态定时器\计数
    orderTimer:null,
    i:0,

  },
  mutations: {
		//这里是set方法
    SETVAL(state, objdata) {
      for (let key in objdata) {
        if (key in state) {
          state[key] = objdata[key];
        }
      }
    },
    // 清除订单id
    clearOrderid(state){
      clearInterval(state.orderTimer); 
      state.i = 0
      state.orderTimer = null
      localStorage.removeItem("orderid");
      if(['buysuccess','buy'].includes(state.dialog)) {
        state.dialog = ''
      }
    },
    
  },
  actions: {
    getUserInfo({state,commit},token){
			let time = parseInt(new Date().getTime());
			let uid =  localStorage.getItem('uid');
			token = token || localStorage.getItem('token');
		
			// if(!token){
			// 	// commit("SETVAL",{loginStep:'logintype'})
      //   Toast('请从游戏内进入！')
			// 	return
			// }
      return  getUserInfo({
          time:time,
          token:token,
          uid:uid,
          access:[time,token]
        }).then(data=>{
          // data.curent.num = 24
          // data.curent.rid = 1
          commit("SETVAL",{userInfo:data})
          console.log(state)
        }).catch(err=>{
          if (err?.status == 4040) {
            localStorage.clear();
            sessionStorage.clear();
            store.commit('SETVAL', {  userInfo: null})
          } else {
            Toast(err.msg || "获取用户信息失败");
          }
        })
    },
    // 领奖、兑换
    getPrize({commit,state},p){
      return new Promise((resolve,reject)=>{
        subPrize(p).then(async data=>{
          // 用户信息接口未返回结果前还可点击领取，所以领取成功后买上改变领取信息s
          await this.dispatch('getUserInfo')
          resolve(data)
        }).catch(err=>{
          reject(err)
        })
      })
    },
    // 获取订单状态
    getOrderstatu({commit,state},p){
      let orderid = localStorage.getItem('orderid')
      let token = localStorage.getItem('token')
      let time = parseInt(new Date().getTime());
      if(!orderid || !token) return
      getOrderStatus({ time,orderid,access:[time,orderid] }, ).then(
        (data) => {
          console.log('订单状态',data)
            if (data > 0) {
              console.log('订单状态成功',data)
              // this.$toast("支付成功");     

              commit('clearOrderid')
              console.log('清除orderid',data)
              // 获取用户信息
              this.dispatch('getUserInfo')
              console.log('获取用户信息',data)
              // 弹出弹框
              // dialogShow.setdialog('success')
              console.log('弹出购买成功提示框',data)
              commit('SETVAL',{dialog:'buysuccess'})
              // Toast("支付成功")
              // dialogShow.setLoading({loading:false,text:'加载中...'})

            }
            
        }
      ).catch(err=>{ 
        // 如果登录过期或签名错误则停止轮训
        if(err.status == 4040 ||err.status == 4041 ) {
          console.log('登录过期或签名错误则停止轮训')
          commit('clearOrderid')
          // dialogShow.setLoading({loading:false,text:'加载中...'})
        }
      })
      
    },

    // 购买成功
    buySuccess({commit,state}) {
      commit('SETVAL',{dialog:'buysuccess'})
    },
  
    // 轮询订单状态
    repeatsearch({commit,state})  {
      let token = localStorage.getItem('token')
      let orderid = localStorage.getItem('orderid')
      if(!orderid || !token) return
      // dialogShow.setLoading({loading:true,text:'查询支付结果...'})
      let  orderTimer = setInterval(() => {
        this.dispatch('getOrderstatu')
        commit('SETVAL',{i:state.i+1})
        if (state.i > 30) {
          commit('clearOrderid')
        }
      }, 1000);
      commit('SETVAL',{orderTimer:orderTimer})
    }
  },
  getters: {
    username: (state) => state.username,
    token: (state) => state.token,
    channel: (state) => state.channel,
    roleName: (state) => state.roleName,
    currentActivity: (state) => state.currentActivity,
  },
  // plugins: [
  //   createPersistedState({
  //     storage: window.localStorage,
  //   }),
  // ],
  modules: {
    // newServer,
    // allServer,
  },
});
