<!--
 * @Author: your name
 * @Date: 2022-01-08 16:00:16
 * @LastEditTime: 2022-01-28 15:35:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sezg-spring-festival-2021\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
import { mapState } from "vuex";
import { quicklogin } from "@/utils/api.js";
export default {
  name: "Home",
  components: {
    // LoginType: resolve =>
    //   require(["@/components/allModals/loginType.vue"], resolve),
  },
  data() {
    return {};
  },
  watch: {
    // $route(to, from) {
    //   let path = to.path;
    // 	const roleInfo =  to.query
    // 	console.log('tag',to.query )
    // 	if(roleInfo && roleInfo.xyid && roleInfo.roleid&& roleInfo.channel){
    // 		this.quickLogin(roleInfo)
    // 	}
    // }
  },
  // computed: {...mapState([
  //   random_num:state=>state.userInfo.random_num
  // ]),
  // },
  methods: {
    quickLogin(roleInfo) {
      let time = parseInt(new Date().getTime());
      let obj = {
        time: time,
        access: [time,roleInfo.role_id],
        rid: roleInfo.role_id,
      };
      quicklogin(obj)
        .then((data) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("uid", data.uid);
          this.$store.dispatch("getUserInfo");
          this.$router.replace(this.$route.path);
          // this.$toast("登录成功");
        })
        .catch((err) => {
          this.$toast(err.msg || "快速登录失败");
        });
    },
  },
  mounted() {
    // const roleInfo =  this.$route.query
    // if(roleInfo?.role_id ){
    //   this.quickLogin(roleInfo )
    // }
  },
};
</script>

<style lang="less" scoped>
.app {
  overflow: hidden;
}
</style>
