<!--
 * @Author: your name
 * @Date: 2021-11-08 10:49:52
 * @LastEditTime: 2022-01-28 15:22:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \three_year\src\components\Modal.vue
-->
<template>
  <div class="mask" v-if="show" @click.self="maskClick">
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="close_btn" @click.stop="close"></div>

        <div class="content-header">
        </div>
        <div class="content-center">
          <slot></slot>
          <div class="btn_box" v-if="surebtn || cancelbtn">
            <div class="sure-btn" v-if="surebtn" v-debounce="sure">
              {{ suretext }}
            </div>
            <div class="cancel-btn" v-if="cancelbtn" @click="close">
              {{ canceltext }}
            </div>
          </div>
        </div>
        <div class="content-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    // 控制弹框显示
    show: {
      type: Boolean,
      required: true,
    },
    // 是否显示确认按钮
    surebtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    suretext: {
      type: String,
      required: false,
      default: "确定",
    },
    // 是否显示取消按钮
    cancelbtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    canceltext: {
      type: String,
      required: false,
      default: "取消",
    },
    // 点击mask是否需要关闭
    maskClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 适用于战令-盼春归
    wholeWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 适用于视频播放
    noBg: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    show(val) {
      if (val) {
        document.body.appendChild(this.$el);
        document.body.style = "height:100%;overflow:hidden;";
      } else {
        // this.$el.parentNode.removeChild(this.$el);
        document.body.style = "";
      }
    },
  },
  methods: {
    maskClick() {
      this.maskClose && this.close();
    },
    close() {
      this.$emit("close");
    },
    sure() {
      this.$emit("sure");
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  overflow: scroll;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  .modal-wrapper {
    width: 702px;
    overflow: hidden;
    height: auto;
    position: relative;
    min-height: 550px;
    max-height: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    .modal-content {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      .close_btn {
          position: absolute;
          right: 30px;
          top: 30px;
          z-index: 1;
          .set-bg(54px,54px,'close.png');
        }
      .content-header {
        .set-bg(702px,57px,'modal-bgt.png');
      }
      .content-center {
        position: relative;
        font-size: 32px;
        width: 702px;
        .set-background('modal-bgc.png');
        min-height: 140px;
        max-height: 1000px;

        overflow-y:scroll ;
        margin: 0 auto;
        .btn_box {
          display: flex;
          justify-content: space-evenly;
        }
        .title {
          text-align: center;
          font-size: 54px;
          color: #333333;
          margin-bottom: 25px;
        }
        .title1 {
          text-align: center;
          font-size: 34px;
          color: #333333;
          margin-bottom: 25px;
        }
        .tit2 {
          font-size: 42px;
          color: #D1401E;
          line-height: 48px;
          padding-top: 30px;
        }
      }
      .content-footer {
        .set-bg(702px,101px,'modal-bgb.png');
      }
      .sure-btn {
        width: 272px;
        height:105px;
        .set-background("sure.png");
      }
      .cancel-btn {
        width: 272px;
        height:105px;
        .set-background("cancle.png");
      }
    }
  }
  .tip {
    width: 100%;
    text-align: center;
    font-size: 26px;
    line-height: 40px;
    color: #C04D34;
    margin-bottom: 20px;
  }
}
</style>
