 export default [
  { top : '6.89333rem', left : '5.17333rem'},
  { top : '7.4rem', left : '4.06667rem'},
  { top : '7.74667rem', left : '2.92rem'},
  { top : '8.22667rem', left : '1.78667rem'},
  { top : '8.66667rem',left : '0.62667rem'},
  
  { top : '9.3rem',left : '2rem'},
  { top : '9.50667rem', left : '3.09333rem'},
  { top :'9.5rem', left : '4.1rem'},
  { top : '9.44rem', left : '5.42667rem'},
  { top : '10.1rem', left : '6.6rem',width:'1.89333rem'},
  
  { top : '10.30667rem', left :'7.90667rem'},
  { top : '10.9rem', left : '6.8rem'},
  { top :  '11.21333rem', left :'8.02667rem'  },
  { top :  '11.66667rem', left :'6.96rem'  },
  { top :  '12.08rem', left : '5.8rem' },
  
  { top : '12.56rem' , left :  '4.68rem'},
  { top : '13.10667rem' , left :  '3.6rem'},
  { top : '13.48rem' , left :  '2.52rem'},
  { top :  '13.94667rem', left : '1.32rem' },
  { top : '14.5rem' , left : '2.6rem' },


  { top : '14.82667rem' , left : '3.85333rem' },
  { top : '14.36rem' , left : '5.05333rem' },
  { top : '13.84rem' , left : '6.05333rem'},
  { top : '14.32rem' , left : '7.29333rem' },
]