<template>
  <Mymodal
    :show="show"
    @close="close"
    @sure="close"
  >
    <div class="title ">选择兑换数量</div>
    <div class="img-info">
      <img  :src="`https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${gift.img}.png`" class="g-img">
      <div class="num">X{{gift.num}}</div>
    </div>
    <div class="expr"></div>
    <div class="spend">
      消耗  
      <img  src="https://wcdn.tomatogames.com/web/guonei/sezg/pop-icon/item100500000001.png"  class="box-img">
      <div class="txt">{{gift.spend * value }}</div>
    </div>
    <div class="steper">
      <van-stepper v-model="value" theme="round" input-width="2.5rem" max="999"  button-size="22" integer />
    </div>
    <div class="box">
      <div class="cancle" @click="close"></div>
      <div class="sure" v-debounce="toconvert"></div>
    </div>
  </Mymodal>
</template>
<script>
  import { mapState } from "vuex"
  
  export default {
    name: '',
    props:{
      gift:{
        type:Object,
        require:true,
      },
      index:{
        type:[String,Number]
      }
    },
    computed:{
      ...mapState({
        show:(state)=>state.dialog === 'convert',
        // 剩余次数
        bnum:(state) => {
          return  state.userInfo?.bnum || 0
        },
        // 兑换记录
        history:(state) => state.userInfo?.prize?.[2] || null,
      })
    },
    data(){
      return {
        value:1,
      }
    },
    methods:{
      close(){
        this.value= 1
        this.$store.commit('SETVAL',{dialog:''})
      },
      toconvert(){
        if(this.$store.state.coinNum < this.gift.spend * this.value){
          this.$toast('剩余兑换币不足！')
          return
        }
        if((this.gift.limit - this.gift.changeNum) < this.value ) {
          let num = this.gift.limit - this.gift.changeNum
          let txt = num <1 ? '已达兑换上限':`只可兑换${num}个`
          this.$toast(txt)
          this.value = num <1 ? 1 :num
          return
        } else if(this.gift.limit < this.value){
          this.$toast(`只可兑换${this.gift.limit}个`)
          this.value = this.gift.limit
          return
        }
        // 填写收获地址
        if(!localStorage.getItem('addressDetail')) {
          // 弹窗填写收获地址
          this.$toast.fail('请先填写收获地址')
          return
        }
        this.gift.changeNum += this.value
        this.$store.state.coinNum -= this.gift.spend * this.value
        this.$toast.success('兑换成功')

        // localStorage.setItem('redeemedGift', )
        this.close()

        // this.$store.dispatch('getPrize',{type:2,rid:this.index,num:this.value}).then(data=>{
        //   this.$toast.success('兑换成功')
        //   this.close()
        // }).catch(err=>{
        //   this.$toast.fail(err.msg|| '兑换失败')
        // })
      },
      
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
  .img-info{
    width: 157px;
    height: 157px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom,#ffe1b7 40%,#fff);
    border-radius: 50%;
    position: relative;
    margin: 20px auto;
    .g-img{
      width: 125px;
    }
    .num {
      position: absolute;
      line-height: 37px;
      .set-bg(93px,37px,'ricon.png');
      color:#fff;
      top: 0px;
      right: -30px;
      font-size: 24px;
      box-sizing: border-box;
      padding-left: 10px;
    }
  }
  .expr {
    font-size: 24px;
    color: #999999;
    text-align: center;
  }
  .spend {
    width: 240px;
    display: flex;
    font-size: 24px;
    color:#000;
    line-height: 47px;
    margin: 20px auto;
    img{
      width: 54px;
      height: 52px;
      margin: 0 5px;
    }
    .txt{
      width: 112px;
      color:#E36649;
      height: 47px;
      border: 1px solid #E36649;
      text-align: center;
    }
    
  }
  .steper {
    width: 300px;
    margin: 0 auto;
    padding-top:20px;

  }
  .cancle {
    .set-bg(272px,105px,'cancle.png');
  }
  .sure{
    .set-bg(272px,105px,'sure.png');
  }
  .box {
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }
</style>